.carousel__container {
  padding: 1rem;
  width: 100%;
}

@media (min-width: 768px) {
  .carousel__container {
    padding: 1rem;
    max-width: 1500px;
    display: flex;
    justify-content: center;
  }

  .carouselWrapper {
    width: 90vw;
  }

  .carousel__button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .carousel__button.disabled {
    cursor:auto;
    opacity: 50%;
  }

  .carousel__button img {
      width: 1rem;
  }
}
