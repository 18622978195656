.item__card {
  margin: 1rem 0.5rem;
  position: relative;
  /*background-image: url("../../media/perfil.jpg");*/
  background-color: #d6d6d6;
  background-size: cover;
  background-position: center top;
  height: 60vh;
}

.seeVideo__button {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
}

.workSummary__container {
  position: absolute;
  bottom: 5%;
  color: #d6d6d6;
  padding: 1% 5%;
  background-color: #333533;
  border-top: 3px solid #ffd100;
  width: 100%;
}
