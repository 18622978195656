.about__container {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  background-color: #d6d6d6;
}

.noScroll{
  overflow: hidden;
}

/* Curriculum vitae */
.curriculum__container {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  border-radius: 6px;
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
}

.curriculum__title{
  margin-top: 1rem;
}

.expanded__modal {
  z-index: 120 !important;
}

.expanded__backdrop {
  z-index: 110 !important;
}

/* DESKTOP VERSION */
@media (min-width: 768px) {

  .backgroundVideo {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .delimiter__section{
    padding-top: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
  }

  .about__container {
    padding-bottom: 0;
    background-color: transparent;
  }

  .curriculum__container {
    height: 95%;
    margin: 0;
    width: 65%;
  }

  .abilExp__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }

  .curriculum__title{
    margin-top: 0;
    margin-left: 2rem;
    font-size: 2rem;
  }

  .abilitiesList__container,
  .experiencesList__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

}
