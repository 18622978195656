.homePage {
  width: 100%;
  height: 100vh;
}

.backgroundVideo {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.homeContent {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoRelleno {
  width: auto;
  height: 30vw;
  opacity: 70%;
}


@media (min-width: 768px) {
  .homeContent {
    height: 100vh;
    display: relative;
  }
  
  .logoRelleno {
    position: absolute;
    width: auto;
    height: 20vh;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .logoLetra {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, 0);
  }

}
