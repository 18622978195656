.mainFooter {
  background-color: #202020;
  padding-top: 1rem;
  border-top: 10px solid #333533;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainFooter__header {
  font-size: 2rem;
  text-align: center;
  padding-bottom: 10px;
  color: #d6d6d6;
  width: 60%;
  max-width: 500px;
  min-width: 200px;
  border-bottom: 5px solid #ffd100;
  margin: 0;
}

.mainFooter__maker {
  background-color: #d6d6d6;
  border-top: 5px solid #333533;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
}

.mainFooter__link {
  color: #d6d6d6;
  text-decoration: none;
}

.mainFooter__maker p {
  margin: 0;
  color: #202020;
}

.mainFooter__maker p a {
  color: #202020;
}

.mainFooter__maker p a:hover {
  color: #333533;
}

@media (min-width: 768px) {
  .mainFooter__maker {
    text-align: right;
  }
}
