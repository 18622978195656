.containerHabilidad{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25vw;
    height: 25vw;
    padding: 1rem;
    margin: 1rem 0.5rem;
    background-color: white;
}

.nombreHabilidad{
    margin: 0;
    text-align: center;
    font-size: 0.8rem;
}

.logoHabilidad {
    margin-bottom: 1rem;
    width: 60%;
    height: auto;
}

@media (min-width: 768px) {  

    .containerHabilidad {
        width: 8rem;
        height: 8rem;
        cursor: pointer;
    }
    .containerHabilidad:hover {

        border: 2px solid #ffd100;
        box-shadow: rgba(255, 238, 50, 0.24) 0px 3px 8px;
        cursor: pointer;
    }
    
  }
  
