.defaultButton {
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  font: inherit;
  font-weight: bold;
  background-color: #ffd100;
  border: 1px solid #ffd100;
  text-align: center;
  text-decoration: none;
  color: #202020;
}

.defaultButton:hover {
  background-color: #ffee32;
  border: 1px solid #ffee32;
  cursor: pointer;
}
