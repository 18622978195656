.modal__container {
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cross {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 15px;
  width: 20px;
  height: auto;
  opacity: 50%;
  cursor: pointer;
}
