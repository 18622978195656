.experience__container {
  background-color: white;
  height: fit-content;
  width: 70vw;
  max-width: 400px;
  max-height: 500px;
  overflow-y: scroll;
  padding: 2rem;
}

.experience__container::-webkit-scrollbar {
  width: 5px;
}

.experience__container::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}

.experience__container::-webkit-scrollbar-thumb {
  background-color: #ffd100;
  border-radius: 100px;
}

.where {
  font-size: 1.5em;
  margin-right: 0.5em;
  font-weight: bold;
}

.what {
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  background-color: #444444;
  padding: 0 0.75rem;
  border-radius: 3px;
}

.when {
  color: #b9b9b9;
  display: inline-block;
  margin-left: 1rem;
  font-size: 0.75em;
  font-style: italic;
}

.description {
  margin: 0;
  text-align: justify;
  overflow: hidden;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.seeMore__button {
  font: inherit;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #b9b9b9;
  padding: 0;
  cursor: pointer;
}
