.redes__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact__text {
  text-align: center;
  color: #d6d6d6;
  margin: 0;
  border-bottom: 1px solid #d6d6d652;
}

.redes__nav {
  width: 100%;
  text-align: center;
  margin: 0;
}

.redes__list {
  list-style: none;
  padding: 0;
}

.redes__list li {
  display: inline;
  margin: 0 1rem;
}

.redes__link {
  color: #d6d6d6;
  text-decoration: none;
}

.redes__link:hover,
.redes__link:active {
  color: #ffd100;
}

.redes__logo {
  width: 30px;
  height: 30px;
}

