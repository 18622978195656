/* Container */
.mainHeader {
  width: 100vw;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainHeader__brand {
  color: #202020;
  text-decoration: none;
  font-weight: bold;
}

.mainHeader__brand img {
  height: 2.5rem;
}

/* Navigation bar */
.mainNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  background: #202020;
  width: 70%;
  height: 100vh;
}

.mainNav__item {
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: #d6d6d6;
  margin: 1rem 0;
}

.mainNav__itemSelected {
  cursor: default;
  border-bottom: 3px solid#ffd100;
  color: #ffd100;
  padding-bottom: 2px;
}

/* Toggle button */
.toggleButton {
  width: 2.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

.toggleButton:focus {
  outline: none;
}

.toggleButton__bar {
  width: 100%;
  height: 0.2rem;
  background: #d6d6d6;
  border-radius: 10px;
  display: block;
  margin: 0.4rem 0;
}

@media (min-width: 768px) {
  .mainHeader {
    padding: 0.5rem 19%;
  }

  /* Navigation bar */
  .mainNav {
    display: block;
    position: static;
    text-align: right;
    background-color: transparent;
    width: auto;
    height: auto;
    
  }

  .mainHeader__brand img {
    height: 2.5rem;
  }

  .mainNav__item {
    margin: 0 1rem;
    text-decoration: none;
    font-weight: normal;
    color: #d6d6d6;
    font-size: 1rem;
  }

  .mainNav__item:hover,
  .mainNav__item:active {
    color: #ffd100;
  }

  .mainNav__itemSelected {
    cursor: default;
    border-bottom: 3px solid#ffd100;
    color: #ffd100;
    padding-bottom: 2px;
  }
}
