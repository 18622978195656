.section__container {
  width: 100%;
}

@media (min-width: 1300px) {
  .section__container {
    width: 80%;
    min-width: 600px;
  }
}
