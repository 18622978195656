.contact__container {
  padding-top: 4rem;
  height: 100vh;
  min-height: 830px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d6d6d6;
}

.contact__cardTitle {
  text-align: center;
  font-size: 1.8rem;
  margin: 0;
  color: #333533;
}

.contact__card {
  margin: 1rem;
  padding: 1rem 0;
  background-color: white;
  max-width: 1200px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
}

.message__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.separator__container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.separator__text {
  margin: 1rem;
}

.separator__line {
  height: 1px;
  width: 30%;
  border: 0;
  border-top: 1px solid black;
  margin-top: 0.5rem;
  padding: 0;
}

@media (min-width: 768px) {

  .backgroundVideo {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .logoRelleno {
    width: auto;
    height: 20vh;
    opacity: 70%;
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }

  .contact__container {
    padding-top: 4rem;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .contact__delimiter {
    position: relative;
    height: 100%;
    margin: 0 10%;
  }

  .contact__cardTitle {
    text-align: center;
    font-size: 1.8rem;
    margin: 0;
    color: #333533;
  }

  .contact__card {
    padding: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    position: absolute;
    width: 100%;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }
}
