.ability__container{
    background: #d6d6d6;
    height: fit-content;
    width: 70vw;
    max-width: 400px;
}

/*
.containerHabilidad:hover {
    width: 27vw;
    height: 27vw;
    border: 2px solid #ffd100;
    box-shadow: rgba(255, 238, 50, 0.24) 0px 3px 8px;
    cursor: pointer;
}*/

.logoName__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 35vh;
}

.abilityName{
    margin: 0;
    text-align: center;
}

.abilityLogo {
    margin-bottom: 1rem;
    width: 50%;
    height: auto;
}

.description__container{
    height: fit-content;
    width: 100%;
    background-color: #202020;
    border-top: 2px solid #ffd100;
}

.description__text {
    color: #d6d6d6;
    padding: 16px;
    margin: 0;
    text-align: justify;
}