.profile__container {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url("../../media/perfil1.jpeg");
  background-size: cover;
  background-position: center;
}

.profile__container:hover {
  background-image: url("../../media/perfil2.jpeg");
}

.profile__description {
  position: absolute;
  bottom: 5%;
  color: #d6d6d6;
  padding: 1% 5%;
  background-color: #333533;
  border-top: 3px solid #ffd100;
}

.profile__summary {
  text-align: justify;
  margin-bottom: 0;
  padding-top: 1rem;
}

.profile__name {
  font-size:xx-large;
}

.profile__description h1,
.profile__description h2,
.profile__description p {
  margin: 0;
}

@media (min-width: 768px) {
  .profile__superContainer {
    height: 95%;
    width: 30%;
  }

  .profile__container {
    position: static;
    height: 65%;
    background-position: top;
  }

  .profile__description {
    position: static;
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

 @media (max-width: 1035px) and (min-width: 768px) {
  .profile__container {
    height: 55%;
  }

  .profile__description {
    height: 45%;
  }
}
