.default__button {
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  font: inherit;
  font-weight: bold;
  background-color: #ffd100;
  border: 1px solid #ffd100;
}

.default__button:hover {
  background-color: #ffee32;
  border: 1px solid #ffee32;
  cursor: pointer;
}

.default__button:disabled,
.default__button:disabled:hover{
  cursor:not-allowed;
  background-color: #ffee3283;
  border: 1px solid #ffee3283;
}
