.contact__title {
  text-align: center;
  font-size: 1.8rem;
  margin: 0;
  color: #333533;
}

.message__form {
  padding: 1rem;
}

.message__form label {
  font-weight: bold;
  display: inline-block;
  margin-top: 1rem;
  width: 100%;
}

.invalid {
  background-color: rgba(255, 0, 0, 0.3);
}

.error__text{
  color: red !important;
}

.message__input,
.message__textarea {
  display: block;
  margin-top: 0.5rem;
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  font: inherit;
}

.message__textarea{
  resize: none;
}

.message__input:focus,
.message__textarea:focus,
.invalid:focus {
  outline: none;
  background: #ffee322d;
  border-color: #ffee32;
}


.message__form button {
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 5px 10px;
  border-radius: 5px;
  font: inherit;
  font-weight: bold;
  background-color: #ffd100;
  border: 1px solid #ffd100;
}

.message__form button:hover {
  background-color: #ffee32;
  border: 1px solid #ffee32;
}

.emailSent__container {
  background-color: white;
  height: fit-content;
  width: 70vw;
  max-width: 400px;
  max-height: 500px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emailSent__icon {
  height: 30px;
  width: auto;
}

@media (min-width: 40rem) {
  .message__form {
    margin: auto;
    width: 25rem;
  }

  .message__form button {
    width: 8rem;
    margin-left: auto;
  }


}
