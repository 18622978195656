@font-face {
  font-family: "Headlines-Bold";
  src: url("./font/Headlines-Bold.otf") format("opentype");
}
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

* {
  box-sizing: border-box;
}

body, html, #root {
  font-family: 'Oswald', sans-serif;
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb {
  background-color: #333533;
  border-radius: 100px;
}
