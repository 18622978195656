.portfolio__container {
  background-color: #d6d6d6;
} 

.trailer__container {
  width: 100%;
  height: 100vh;
  display: relative;
}

.trailer__video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.trailerAccess__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(214, 214, 214, 0.7);
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 1rem 0;
}

.trailer__text {
  color: #202020;
  margin-top: 0;
}

.seeTrailer__button {
  width: 80%;
}

.scroll__container {
  text-align: center;
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 2%;
  transform: translate(-50%, 0);
  cursor: pointer;
}

.scroll__text {
  color: #d6d6d6;
  margin: 0;
}

.scroll__button {
  color: #d6d6d6;
  background-color: transparent;
  border: none;
}

.scroll__icon {
  width: 10vw;
}

.allWork__container {
  padding: 4rem 0;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lastWork__title {
  color: #d6d6d6;
  text-align: center;
  padding: 1rem 0;
  border-top: 3px solid #ffd100;
  border-bottom: 3px solid #ffd100;
  background-color: #202020;
  width: 100%;
}

.seeAll__button {
  width: 85%;
}

@media (min-width: 768px) {
  .trailerAccess__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(214, 214, 214, 0.7);
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    padding: 1rem 0;
  }

  .seeTrailer__button {
    width: 80%;
    max-width: 400px;
  }

  .seeAll__button {
    max-width: 400px;
  }
}
