.experiences__item {
  list-style: none;
  padding-right: 40px;
}

/* Para que en el overlayd e movil se vea bien */
div > .experiences__item {
  padding-right: 0;
}

.where {
  font-size: 1.5em;
  margin-right: 0.5em;
}

.what {
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  background-color: #444444;
  padding: 0 0.75rem;
  border-radius: 3px;
}

.when {
  color: #b9b9b9;
  display: inline-block;
  margin-left: 1rem;
  font-size: 0.75em;
  font-style: italic;
}

.description {
  margin: 0;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.seeMore__button {
  font: inherit;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #b9b9b9;
  padding: 0;
  cursor: pointer;
}

/* BORDERS AND BULLETS */
.experiences__item {
  position: relative; /* so that pseudoelements are positioned relatively to their "li"s*/
  /* use padding-bottom instead of margin-bottom.*/
  margin-bottom: 0; /* This overrides previously specified margin-bottom */
  padding-bottom: 2em;
}

.experiences__item:after {
  /* bullets */
  content: "";
  position: absolute;
  left: -21.5px; /*adjust manually*/
  top: 0.75rem;
  height: 11px;
  width: 11px;
  border: 1px solid #d6d6d6;
  background-color: #d6d6d6;
  border-radius: 50%;
}

.experiences__item:before {
  /* lines */
  content: "";
  position: absolute;
  left: -16px; /* adjust manually */
  top: 2rem;
  border-left: 1px solid #d6d6d6;
  height: 80%;
  width: 1px;
}

.experiences__item:last-child:before {
  /* last li's line */
  height: 0px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
}

@media (min-width: 768px) {
  .experiences__item {
    display: inline-block;
    margin: 0.5rem 1.5rem;
    max-width: 250px;
    padding: 0;
  }

  .experiences__item:before {
    display: none;
  }


  .seeMore__button:hover {
    color: #ffd100;
  }

}

@media (max-width: 1300px) {
  .experiences__item {
    max-width: 100%;
  }
}
