/* Experiences */
.experiences__list {
  margin: 0;
}

.all__card {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: white;
  width: 90vw;
  padding: 0 2rem 2rem 2rem;
  max-height: 70vh;
  overflow-y: auto;
}

.all__card::-webkit-scrollbar {
  width: 5px;
}

.all__card::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}

.all__card::-webkit-scrollbar-thumb {
  background-color: #ffd100;
  border-radius: 100px;
}

.all__header {
  width: 100%;
  text-align: center;
}

@media (min-width: 768px) {
  .experiences__list {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-start;
  }

  .all__card {
    width: 70vw;
  }

  .seeAll__button {
    align-self: flex-end;
    width: 30%;
    margin-top: 1rem;
    margin-right: 2rem;
  }


}
